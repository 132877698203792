<template>
  <div class="animated-counter-component">
    {{ animatedNumber }}
  </div>
</template>
<script>
import gsap from "gsap";
export default {
  name: "AnimatedCounter",
  props: {
    number: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tweenedNumber: 0,
    };
  },
  computed: {
    animatedNumber() {
      return this.tweenedNumber.toFixed(0);
    },
  },
  watch: {
    number: {
      handler(newValue) {
        setTimeout(
          () => gsap.to(this.$data, { duration: 0.5, tweenedNumber: newValue }),
          500
        );
      },
      immediate: true,
    },
  },
};
</script>